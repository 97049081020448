import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Chat,
  FooterContainer,
  Language,
  IframeContainer,
  IbanContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contacto")}</Language>
              <Para>
                {t(`Si tienes alguna duda o sugerencia, no dudes en contactarnos.`)}
              </Para>
              <a href="https://wa.me/+34636019551/?text=%C2%A1Hola+Celia%21+Ten%C3%ADa+algo+que+comentarte">
                <Chat>{t(`Whatsapp Celia`)}</Chat>
              </a>
              <a href="https://wa.me/+34664097952/?text=%C2%A1Hola+Andr%C3%A9s%21+Ten%C3%ADa+algo+que+comentarte%0D%0A">
                <Chat>{t(`Whatsapp Andrés`)}</Chat>
              </a>
              <IbanContainer>ES03 1465 0100 9417 5960 8056</IbanContainer>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Dirección")}</Language>
              <Para>Villas de Pomar</Para>
              <Para>N-620, 224, 37427 Pedrosillo el Ralo,</Para>
              <Para>Salamanca</Para>
              <IframeContainer src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.7998429638524!2d-5.5583615!3d41.051505999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f31e17c0fafe7%3A0x36acdcee0b02c25d!2sVillas%20De%20Pomar!5e0!3m2!1ses!2ses!4v1708959525454!5m2!1ses!2ses" height="170" loading="lazy"></IframeContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="70px"
                  height="70px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              Andrés y Celia 2024
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
